<template>
  <div class="appeals-form appeals-form--search portal-wrapper" v-if="searchPage">
    <pre class="notify notify--green success-notifications" v-if="notify" v-html="notify"></pre>
    <form class="appeals-form" @submit.prevent="submitSearch">
      <div class="form-row form-row--1">
        <div class="search-form">
          <span class="search-form__title">Поиск обращения по ID</span>
          <div class="search-form__row">
            <InputComponent v-model.trim="searchQuery" placeholder="ID">
              <template v-slot:action>
                <IconComponent category="vuesax-bold" name="search-normal"/>
              </template>
            </InputComponent>
            <button class="btn btn--main">{{ searchButtonState }}</button>
          </div>
        </div>
      </div>
    </form>
    <div class="appeals-results">
      <template v-if="result && result.id">
        <span class="appeals-results__title">Результаты поиска обращения</span>
        <div class="appeals-results__item">
          <div class="appeals-results__item-header">
            <div class="appeals-results__item-header-left">
              <div class="appeals-results__item-top">
                <b>№ {{ result.uuid }}</b>
                <span>от {{ result.created_at | humanDateShort }}</span>
              </div>
              <span class="appeals-results__item-title">
                {{ result.surname }} {{ result.name }} {{ result.patronymic }}
              </span>
            </div>
            <div class="appeals-results__item-status" v-if="result.status">{{ result.status.name }}</div>
          </div>
          <div class="appeals-results__item-description">{{ result.description }}</div>
          <div class="appeals-results__item-response" v-if="result.department_comment">
            <span class="appeals-results__item-response-title">Ответ Министерства</span>
            <div class="appeals-results__item-response-description">{{ result.department_comment }}</div>
          </div>
        </div>
      </template>
      <span
        class="appeals-results__title"
        v-else-if="searchQuery && searchQuery.length && !result && searchSubmitted"
      >
        Обращения с таким идентификатором не существует
      </span>
    </div>
  </div>
  <form class="appeals-form" @submit.prevent="appealSubmit" v-else-if="mounted">
    <div class="form-row form-row--1">
      <SelectComponent
        v-if="options_gov"
        v-model="form.department.value"
        :errors="form.department.errors"
        label-name="title"
        :options="options_gov"
        title="Адресат"
        placeholder=""
        :clearable="false"
      />
    </div>
    <div class="form-row form-row--3">
      <InputComponent
        v-model.trim="form.surname.value"
        :errors="form.surname.errors"
        title="Фамилия"
        placeholder=""
        required
        id="surname"
      />
      <InputComponent
        id="name"
        v-model.trim="form.name.value"
        :errors="form.name.errors"
        title="Имя"
        placeholder=""
      />
      <InputComponent
        v-model.trim="form.patronymic.value"
        :errors="form.patronymic.errors"
        title="Отчество"
        placeholder=""
        id="patronymic"
      />
    </div>
    <div class="form-row form-row--2">
      <InputComponent
        v-model.trim="form.email.value"
        :errors="form.email.errors"
        title="Электронная почта"
        placeholder=""
        type="email"
        id="email"
        required
      />
      <InputComponent
        v-model.trim="form.phone.value"
        :errors="form.phone.errors"
        title="Телефон"
        placeholder="+7 (___) ___-__-__"
        mask="+7 (###) ###-##-##"
        id="phone"
      />
    </div>
    <div class="form-row form-row--1">
      <TextareaComponent
        v-model="form.description.value"
        title="Текст обращения"
        placeholder=""
        :errors="form.description.errors"
        required
        id="description"
      />
    </div>
    <div class="form-row form-row--3">
      <FileInputComponent
        :messages="form.file.messages"
        :errors="form.file.errors"
        v-model="file"
        title="Файл"
      />
    </div>
    <div class="form-row form-row--1">
      <div class="appeals-form__footer">
        <button type="submit" id="form-submit" class="btn btn--main">{{ appealButtonState }}</button>
        <div v-if="department && department.privacy">
          Нажимая на кнопку, вы даете согласие на обработку персональных данных и соглашаетесь с
          <a target="_blank" :href="$store.state._env.MEDIA_ENDPOINT + department.privacy.path">
            политикой конфиденциальности
          </a>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import SelectComponent from "components/inputs/select/index.vue";
import TextareaComponent from "components/inputs/TextareaComponent.vue";
import FileInputComponent from "components/inputs/FileInputComponent.vue";
import APPEAL_CREATE from "gql/mutations/AppealCreate.graphql";
import FILES_UPLOAD from "gql/mutations/FilesUpload.graphql";
import APPEALS_ITEM from "gql/queries/appeals_item.graphql";
import IconComponent from "components/IconComponent.vue";
import GOVERNMENTS_ITEM from "gql/queries/governments_item.graphql";
import GOVERNMENTS from "gql/queries/governments.graphql";

export default {
  name: "AppealsForm",
  data() {
    let department = this.$store.state.department;
    return {
      /**
       * 0 - нет загрузки
       * 1 - идет загрузка файла
       * 2 - идет отправка формы
       */
      selectValue: null,
      options_gov: null,
      mounted: false,
      searchPage: false,
      searchSubmitted: false,
      searchQuery: null,
      loadingState: 0,
      result: null,
      // departments: [
      //   {
      //     id: 0,
      //     title: "Портал мер поддержки членов семей мобилизованных граждан",
      //   },
      // ],
      notify: null,
      file: null,
      form: {
        name: {
          value: null,
          defaultValue: null,
          errors: [],
        },
        surname: {
          value: null,
          defaultValue: null,
          errors: [],
        },
        patronymic: {
          value: null,
          defaultValue: null,
          errors: [],
        },
        email: {
          value: null,
          defaultValue: null,
          errors: [],
        },
        phone: {
          value: null,
          defaultValue: null,
          errors: [],
        },
        file: {
          value: null,
          defaultValue: null,
          errors: [],
          messages: [
            "Поддерживаемые форматы: pdf, docx.\n" +
            "Размер: Не более 1 Мб.\n" +
            "Количество: Не более 1 файла.",
          ],
        },
        description: {
          value: null,
          defaultValue: null,
          errors: [],
        },
        department: {
          value: null,
          defaultValue: null,
          errors: [],
        },
      },
    };
  },
  computed: {
    searchButtonState() {
      if (this.loadingState === 1) {
        return "Загрузка..";
      }
      if (this.loadingState === 2) {
        return "Загрузка..";
      }
      return "Поиск";
    },
    appealButtonState() {
      if (this.loadingState === 1) {
        return "Загрузка файла..";
      }
      if (this.loadingState === 2) {
        return "Отправка письма...";
      }
      return "Отправить письмо";
    },
  },
  mounted() {
    this.$apollo
      .query({
        query: GOVERNMENTS_ITEM,
        variables: {
          id: parseInt(this.$route.params.id),
        },
      })
      .then(({data}) => {
        this.governments_id = data.governments_item.id;
        this.selectValue = {
          id: data.governments_item.id,
          title: data.governments_item.title,
        };

        this.form.department.value = this.selectValue;
        this.searchSubmitted = true;
        this.loadingState = 0;
      });

    this.$apollo
      .query({
        query: GOVERNMENTS,
      })
      .then(({data}) => {
        let gov = data.governments.map((item) => {
          return {
            id: item.id,
            title: item.title,
          };
        });
        this.options_gov = gov;
      });

    this.searchPage = this.$route.hash === "#search";
    this.mounted = true;
  },
  watch: {
    searchQuery() {
      this.searchSubmitted = false;
    },
  },
  methods: {
    submitSearch() {
      if (this.loadingState === 0) {
        this.loadingState = 1;
        this.$apollo
          .query({
            query: APPEALS_ITEM,
            variables: {
              uuid: this.searchQuery,
            },
          })
          .then(({data}) => {
            if (data && data.appeals_item) {
              this.result = data.appeals_item;
            }
            this.searchSubmitted = true;
            this.loadingState = 0;
          });
      }
    },
    appealSubmit() {
      if (this.loadingState === 0) {
        this.resetErrors();
        if (this.file && this.file.length) {
          this.submitFile();
        } else {
          this.submitForm();
        }
      }
    },
    submitFile() {
      this.loadingState = 1;
      this.$apollo
        .mutate({
          mutation: FILES_UPLOAD,
          variables: {
            files: this.file,
          },
        })
        .then(({data}) => {
          if (data && data.FilesUpload && data.FilesUpload.length) {
            this.form.file.value = data.FilesUpload[0];
            this.submitForm();
          }
        })
        .catch(() => {
          this.loadingState = 0;
          this.$notify({
            title: "Ошибка",
            text: "Загрузка файла не удалась",
            duration: 5000,
            speed: 200,
            type: "error",
          });
        });
    },
    submitForm() {
      this.loadingState = 2;
      let variables = {};
      Object.keys(this.form).forEach((key) => {
        variables[key] = this.form[key].value;
      });
      variables.government = parseInt(this.governments_id);

      this.$apollo
        .mutate({
          mutation: APPEAL_CREATE,
          variables: variables,
        })
        .then(({data}) => {
          if (data && data.AppealCreate) {
            this.$notify({
              title: "Отправлено",
              text: data.AppealCreate.message,
              duration: 5000,
              speed: 200,
              type: "success",
            });
            this.resetForm();
            window.focus();
            window.scrollTo(0, 0);
            this.notify =
              "Ваше обращение отправлено! \nНомер 12345543563 обращения отправлен на электронную почту, указанную Вами при заполнении обращения.";
            history.pushState({}, null, location.origin + location.pathname + "#search");
            this.searchPage = true;
          }
          this.loadingState = 0;
        })
        .catch(({graphQLErrors}) => {
          this.loadingState = 0;
          this.parseGqlErrors(graphQLErrors);
        });
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].errors = err.extensions.validation[key];
            }
          });
        }
      });
    },
    resetForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].value = JSON.parse(this.form[key].defaultValue);
      });
    },
    resetErrors() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].errors = undefined;
      });
    },
  },
  metaInfo: {
    title: "Электронная форма обращения",
  },
  components: {IconComponent, FileInputComponent, TextareaComponent, SelectComponent, InputComponent},
};
</script>

<style lang="stylus">
@import "~@/styles/components/notify.styl"
</style>
