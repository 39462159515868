<template>
  <div class="textarea">
    <label
      :class="{
        'textarea--error': errors.length,
        'textarea--focus': focused,
        'textarea--action': $slots.action,
      }"
      class="textarea__label"
    >
      <span class="textarea__title">
        {{ title }}
        <span>{{ required ? "*" : "" }}</span>
      </span>
      <span class="textarea__container">
        <button v-if="$slots.action" class="textarea__action" type="button" @click.prevent="$emit('submit')">
          <slot name="action" />
        </button>
        <textarea
          ref="input"
          :autofocus="autofocus"
          :value="value"
          :disabled="disabled"
          :placeholder="placeholder"
          :rows="rows"
          :id="id"
          class="textarea__field"
          @blur="blur"
          @click="$emit('click')"
          @focus="focus"
          @input="$emit('input', $event.target.value)"
          :required="required"
        ></textarea>
      </span>
    </label>
    <div v-if="errors && errors.length" class="textarea__errors">
      <pre v-for="(e, i) in errors" :key="i">{{ e }}</pre>
    </div>
    <div v-if="messages && messages.length" class="textarea__messages">
      <pre v-for="(m, i) in messages" :key="i">{{ m }}</pre>
    </div>
    <slot name="footer"></slot>
  </div>
</template>

<script>
export default {
  name: "TextareaComponent",
  props: {
    id: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default() {
        return "";
      },
    },
    rows: {
      type: [String, Number],
      default() {
        return 5;
      },
    },
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
    messages: {
      type: Array,
      default() {
        return [];
      },
    },
    value: [String, Number],
    disabled: Boolean,
    autofocus: Boolean,
    required: Boolean,
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    notEmpty() {
      if (typeof this.value === "string") {
        return this.value && this.value.length;
      }
      return this.value !== null;
    },
  },
  methods: {
    focus() {
      if (!this.focused) {
        this.focused = true;
      }
      this.$emit("focus");
    },
    blur() {
      if (this.focused) {
        this.focused = false;
      }
      this.$emit("blur");
    },
  },
};
</script>

<style lang="stylus">
.textarea {
  display grid
  grid-gap 5px

  &__label {
    display grid
    grid-gap 5px
    position relative
  }

  &__container {
    display flex
    align-items center
    background: var(--white);
    border: 1px solid var(--gray-dark);
    border-radius: 5px;

    ^[0]--focus & {
      border: 1px solid var(--main);
      box-shadow: 0 0 0 3px var(--main-o15);
    }

    ^[0]--error & {
      border: 1px solid var(--red);
      box-shadow: 0 0 0 3px var(--red-o15);
    }
  }

  &__title {
    font-weight: 500;
    font-size: 0.75em;
    line-height: 14px;
    color: var(--dark-light);

    span {
      color: var(--red);
    }
  }

  &__action {
    padding 15px
    padding-right 10px
    background none
    border-radius 5px
    display inline-flex
    align-items center
    justify-content center
    border 1px solid transparent

    &:focus {
      border-color var(--main)
      box-shadow: 0 0 0 3px var(--main-o15);
    }

    > .icon {
      width 20px
      height: 20px;

      svg path {
        fill var(--dark)
      }
    }
  }

  &__field {
    border-radius: 5px;
    padding 12px 15px
    outline none
    border none
    background none
    width 100%
    resize vertical
    font-family var(--font-regular)
    font-weight: 500;
    font-size: 1em;
    line-height: 26px;
    color: var(--dark);

    ^[0]--action & {
      padding-left 0
    }

    &::placeholder {
      font-weight: 500;
      font-size: 1em;
      line-height: 26px;
      color: var(--dark);
      opacity: 0.3;
    }

    &::-ms-input-placeholder {
      font-weight: 500;
      font-size: 1em;
      line-height: 26px;
      color: var(--dark);
      opacity: 0.3;
    }
  }

  &__messages,
  &__errors {
    font-weight: 500;
    font-size: 0.75em;
    line-height: 14px;
    display grid
    grid-gap 5px
  }

  &__messages {
    color var(--dark-light)
  }

  &__errors {
    color var(--red)
  }
}
</style>
