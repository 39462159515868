<template>
  <main>
    <Header />
    <PortalForm class="portal-wrapper" />
  </main>
</template>

<script>
import Header from "./components/Header.vue";
import PortalForm from "./components/PortalForm.vue";
export default {
  components: {
    Header,
    PortalForm,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/components/notify.styl"
.portal-wrapper {
  width 100%
  max-width var(--max-width)
  margin 50px auto
}
.search-form {
  display grid
  grid-gap 10px

  &__title {
    font-weight: 700;
    font-size: 1.125em;
    line-height: 28px;
    color: var(--dark);
  }

  &__row {
    display grid
    grid-template-columns 1fr auto
    grid-gap 10px
  }
}

.appeals-form {
  background: var(--white);
  border: 1px solid var(--gray-dark);
  border-radius: 10px;
  padding 30px
  display grid
  grid-gap 20px

  &--search {
    background none
    padding 0
    border-radius 0
    border 0
    grid-gap 30px
  }

  .input__container,
  .textarea__container,
  .select__field-container,
  .select__action,
  .file-input__container {
    background var(--gray)
  }

  &__footer {
    display grid
    grid-gap 20px
    grid-template-columns auto 1fr
    font-weight: 500;
    font-size: 0.875em
    line-height: 22px;
    color: var(--dark-light);
    align-items center
    +below(768px) {
      grid-template-columns auto
    }

    a {
      text-decoration underline
      color: var(--dark-light);
    }
  }
}

.form-row {
  display grid
  grid-gap 20px

  &--1 {
    grid-template-columns 1fr
  }

  &--2 {
    grid-template-columns repeat(2, 1fr)
    +below(560px) {
      grid-template-columns 1fr
    }
  }

  &--3 {
    grid-template-columns repeat(3, 1fr)
    +below(768px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(560px) {
      grid-template-columns 1fr
    }
  }
}

.appeals-results {
  display grid
  grid-gap 20px

  &__title {
    font-weight: 500;
    font-size: 1em;
    line-height: 26px;
    color: var(--dark);
  }

  &__item {
    padding 20px
    background: var(--white);
    border-radius: 10px;
    display grid
    grid-gap 20px
  }

  &__item-header {
    display grid
    grid-template-columns 1fr auto
    grid-gap 15px
    padding-bottom 20px
    border-bottom 1px solid var(--gray-dark)
    align-items start
  }

  &__item-header-left {
    display grid
    grid-gap 5px
  }

  &__item-status {
    background: var(--dark-light);
    border: 1px solid var(--dark-light);
    border-radius: 5px;
    padding 5px 10px
    font-style: normal;
    font-weight: 500;
    font-size: 0.75em;
    line-height: 14px;
    color: var(--white);
  }

  &__item-top {
    display flex
    align-items center
    gap 24px
    font-size: 0.75em;
    line-height: 14px;
    color: var(--dark-light);

    & > *:not(:last-child) {
      position relative

      &:after {
        content "•"
        font-weight 700
        absolute right -14px top bottom
        margin auto
        font-size: 1em;
        line-height: 14px;
        color: var(--dark-light);
      }
    }
  }

  &__item-title {
    font-weight: 500;
    font-size: 1.125em;
    line-height: 28px;
    color: var(--dark);
  }

  &__item-description {
    font-weight: 500;
    font-size: 1.125em;
    line-height: 28px;
    color: var(--dark);
  }

  &__item-response {
    padding-left 20px
    display grid
    grid-gap 10px
  }

  &__item-response-title {
    font-weight: 500;
    font-size: 1em;
    line-height: 26px;
    color: var(--dark-light);
  }

  &__item-response-description {
    font-weight: 500;
    font-size: 1.125em;
    line-height: 28px;
    color: var(--dark);
  }
}
</style>
